

//
// bootstrap-colorpicker.scss
//


.colorpicker-element {
    .input-group-addon, .add-on {
        i {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: text-top;
            cursor: pointer;
        }
    }
    .btn-light{
        border-color: $input-border-color;
    }
}
